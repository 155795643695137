import React from 'react';
import LazyLoad from 'react-lazy-load';
import SeoLink from '../../../../../framework/base/common/components/shared/elements/SeoLink';
import { ENTITY_IMAGE_THUMBNAIL_WIDTH_DESKTOP_150 } from '../../../../../framework/base/common/constants/BaseCommonConstants';
import { BASE_RENDERER_ROUTE_PATHS_IMAGES } from '../../../../../framework/base/common/constants/path/baseRendererRoutePathsImages';
import { advertUrlHelperGetEntityDetailPathname } from '../../../../helpers/advertUrlHelpers';
import type { AdvertListItemHomePageApiModel } from '../../../../models/api/advert/specified/AdvertListItemHomePageApiModel';
import AdvertSharedContentService from '../../../../services/content/advert/AdvertSharedContentService';
import styles from './AdvertsListItemHomePage.module.scss';
import AdvertsListItemHomePagePinButton from './AdvertsListItemHomePagePinButton';

function helperGetDetailHeadTitle(item: AdvertListItemHomePageApiModel, date: string | undefined): string {
  const parts = [
    AdvertSharedContentService.getAdvertLabel(item.p ? undefined : true, item.t, item.a),
    item.l,
  ];

  const base = parts.filter((part) => !!part).join(', ');

  if (date) {
    return `${base}, ${date}`;
  } else {
    return base;
  }
}

const ENTITY_IMAGE_NO_IMAGE_THUMBNAIL = '/images/app/item/no-image-thumbnail.svg';

const thumbnailWithAndHeight = `${ENTITY_IMAGE_THUMBNAIL_WIDTH_DESKTOP_150}px`;

interface AdvertsListItemHomePageProps {
  item: AdvertListItemHomePageApiModel;
  locationSearch: string;
  index: number;
  isActive: boolean;
  formatDate: (utcIsoString: string) => string | undefined;
  handleOnClickPin: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

// let renderCounter = 0;

const AdvertsListItemHomePage: React.FC<AdvertsListItemHomePageProps> = ({
  item,
  locationSearch,
  index,
  isActive,
  formatDate,
  handleOnClickPin
}: AdvertsListItemHomePageProps) => {

  // console.debug(`Render AdvertsListItemHomePage ${++renderCounter}`);

  const pathname = advertUrlHelperGetEntityDetailPathname({ id: item.i, slug: item.s, animal: item.a, type: item.t });

  const advertPublicCreatedAtLocal = item.d ? formatDate(item.d) : undefined;

  const advertTitle = AdvertSharedContentService.getAdvertLabel(item.p ? undefined : true, item.t, item.a);
  const advertDetailHeadTitle = helperGetDetailHeadTitle(item, advertPublicCreatedAtLocal);

  const thumbnailForceDisplay = index !== undefined ? index < 10 : false;
  const thumbnailSrc = item.mi && item.d
    ? BASE_RENDERER_ROUTE_PATHS_IMAGES + `/thumbnail/${item.md ? item.md : item.d.substring(0, 10)}/${item.i}/${item.mi}.webp`
    : ENTITY_IMAGE_NO_IMAGE_THUMBNAIL;

  return (
    <li className={styles[`app-li-animal-${item.a}-type-${item.t}`]}>
      {thumbnailForceDisplay && (
        <img
          src={thumbnailSrc}
          alt={advertDetailHeadTitle}
          width={thumbnailWithAndHeight}
          height={thumbnailWithAndHeight}
        />
      )}
      {!thumbnailForceDisplay && (
        <LazyLoad>
          <img
            src={thumbnailSrc}
            alt={advertDetailHeadTitle}
            width={thumbnailWithAndHeight}
            height={thumbnailWithAndHeight}
          />
        </LazyLoad>
      )}
      <div className={styles['app-div']}>
        <h2 className={styles['app-h2']}>
          <SeoLink
            seoTo={pathname}
            uiTo={{
              pathname: pathname,
              search: locationSearch, // TODO CREATE SEARCH - all current filters schould be in query (search)
            }}
            className={styles[`app-link${item.g !== undefined ? `-gender-${item.g ? 'male' : 'female'}` : ''}`]}
            title={advertDetailHeadTitle}
            label={advertTitle}
          />
          {advertPublicCreatedAtLocal && item.d && (
            <>
              {' '}
              <time className={styles['app-time']} dateTime={item.d}>
                {advertPublicCreatedAtLocal}
              </time>
            </>
          )}
        </h2>
        <p className={styles['app-p']}>
          {item.r}
        </p>
        <aside className={styles['app-aside']}>
          <span className={styles[`app-location-${item.p ? 'private' : `shelter`}`]}>{item.l}</span>
          {' '}
          <AdvertsListItemHomePagePinButton
            isActive={isActive}
            handleOnClickPin={handleOnClickPin}
          />
        </aside>
      </div>
    </li>
  );
};

export default AdvertsListItemHomePage;

