import React, { useEffect, useState, type PropsWithChildren } from 'react';
import { Link, type To } from 'react-router-dom';

interface SeoLinkHtmlProps {
  seoTo: To;
  uiTo: To | undefined;
  title: string | undefined;
  className?: string | undefined;
}

const SeoLinkHtml: React.FC<PropsWithChildren<SeoLinkHtmlProps>> = ({ seoTo, uiTo, children, title, className }: PropsWithChildren<SeoLinkHtmlProps>) => {

  const [to, setTo] = useState<To>(seoTo);

  useEffect(() => {
    if (uiTo) {
      setTo(uiTo);
    }
  }, [uiTo]);

  return (
    <Link
      to={to}
      title={title}
      className={className}
    >
      {children}
    </Link>
  );
};

export default SeoLinkHtml;
