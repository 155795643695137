import React from 'react';
import type { To } from 'react-router-dom';
import SeoLinkHtml from '../../../../../framework/base/common/components/shared/elements/SeoLinkHtml';
import type { DeepReadonly } from '../../../../../framework/base/common/constants/types/UtilityTypes';
import type { Taxonomy } from '../../../../../framework/base/common/models/system/Taxonomy';
import type { IHomePageUrlGenerator } from '../../../../../framework/gis/common/hooks/IHomePageUrlGenerator';
import HomePageUrlQueryMapper from '../../../../helpers/mappers/HomePageUrlQueryMapper';
import type { HomePageQuery } from '../../../../models/system/home/queries/HomePageQuery';
import TaxonomyContentService from '../../../../services/content/TaxonomyContentService';
import styles from './AdvertsListFilters.module.scss';

const types: Taxonomy[] = TaxonomyContentService.getTypes();

const mapper = HomePageUrlQueryMapper.factory();

function generateUrlFilterType(query: DeepReadonly<HomePageQuery>, value: string): To {
  const result = mapper.generateHomePageFriendlyUrl({
    ...query,
    filters: {
      ...query.filters,
      type: (query.filters?.type?.includes(value)
        ? query.filters.type.filter((v) => v != value)
        : [...query.filters?.type || [], value]
      ).sort(),
    }
  });

  return result;
}

interface AdvertsListFiltersTypesProps {
  urlGenerator: IHomePageUrlGenerator;
  currentQueryUi: DeepReadonly<HomePageQuery>;
  currentQuerySeo: DeepReadonly<HomePageQuery>;
}

// let renderCounter = 0;

const AdvertsListFiltersTypes: React.FC<AdvertsListFiltersTypesProps> = ({ urlGenerator, currentQueryUi, currentQuerySeo }: AdvertsListFiltersTypesProps) => {

  // console.debug(`Render AdvertsListFiltersTypes ${++renderCounter}`);

  return types.map((type, index) => (
    <React.Fragment key={index}>
      <SeoLinkHtml
        uiTo={generateUrlFilterType(currentQueryUi, type.code)}
        seoTo={generateUrlFilterType(currentQuerySeo, type.code)}
        title={type.label}
        className={styles[urlGenerator.query.filters?.type?.includes(type.code) ? 'app-type-link-active' : 'app-type-link']}
      >
        <span className={styles[`app-type-span-${type.code}`]} />
        {type.label}
      </SeoLinkHtml>
      {index < types.length ? ' ' : ''}
    </React.Fragment>
  ));
};

export default AdvertsListFiltersTypes;
