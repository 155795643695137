import React from 'react';
import type { To } from 'react-router-dom';
import SeoLink from '../../../../../framework/base/common/components/shared/elements/SeoLink';
import type { DeepReadonly } from '../../../../../framework/base/common/constants/types/UtilityTypes';
import type { Taxonomy } from '../../../../../framework/base/common/models/system/Taxonomy';
import type { IHomePageUrlGenerator } from '../../../../../framework/gis/common/hooks/IHomePageUrlGenerator';
import HomePageUrlQueryMapper from '../../../../helpers/mappers/HomePageUrlQueryMapper';
import type { HomePageQuery } from '../../../../models/system/home/queries/HomePageQuery';
import TaxonomyContentService from '../../../../services/content/TaxonomyContentService';
import styles from './AdvertsListFilters.module.scss';

const animals: Taxonomy[] = TaxonomyContentService.getAnimals();

const mapper = HomePageUrlQueryMapper.factory();

function generateUrlFilterAnimal(query: DeepReadonly<HomePageQuery>, value: string): To {
  const result = mapper.generateHomePageFriendlyUrl({
    ...query,
    filters: {
      ...query.filters,
      animal: query.filters?.animal?.includes(value)
        ? query.filters.animal.filter((v) => v != value).sort()
        : [value],
    }
  });

  return result;
}

interface AdvertsListFiltersAnimalsProps {
  urlGenerator: IHomePageUrlGenerator;
  currentQueryUi: DeepReadonly<HomePageQuery>;
  currentQuerySeo: DeepReadonly<HomePageQuery>;
}

// let renderCounter = 0;

const AdvertsListFiltersAnimals: React.FC<AdvertsListFiltersAnimalsProps> = ({ urlGenerator, currentQueryUi, currentQuerySeo }: AdvertsListFiltersAnimalsProps) => {

  // console.debug(`Render AdvertsListFiltersAnimals ${++renderCounter}`);

  return animals.map((animal, index) => (
    <React.Fragment key={index}>
      <SeoLink
        uiTo={generateUrlFilterAnimal(currentQueryUi, animal.code)}
        seoTo={generateUrlFilterAnimal(currentQuerySeo, animal.code)}
        label={animal.label}
        title={undefined}
        className={styles[urlGenerator.query.filters?.animal?.includes(animal.code) ? 'app-animal-link-active' : 'app-animal-link']}
      />
      {index < animals.length ? ' ' : ''}
    </React.Fragment>
  ));
};

export default AdvertsListFiltersAnimals;
