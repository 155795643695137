import loadable from '@loadable/component';
import React, { useMemo } from 'react';
import type { DeepReadonly } from '../../../../../framework/base/common/constants/types/UtilityTypes';
import type { IHomePageUrlGenerator } from '../../../../../framework/gis/common/hooks/IHomePageUrlGenerator';
import type { HomePageQuery } from '../../../../models/system/home/queries/HomePageQuery';
import styles from './AdvertsListFilters.module.scss';
import AdvertsListFiltersAnimals from './AdvertsListFiltersAnimals';
import AdvertsListFiltersTypes from './AdvertsListFiltersTypes';
import AdvertPublicCreatedAtPeriodDropdownNative from './filters/AdvertPublicCreatedAtPeriodDropdownNative';

const AdvertsListAdvancedFiltersExpanded = loadable(() => import(/* webpackChunkName: "page-home-adverts-list-advanced-filters-expanded" */'./AdvertsListAdvancedFiltersExpanded'));
const AdvertsListAdvancedFiltersApplied = loadable(() => import(/* webpackChunkName: "page-home-adverts-list-advanced-filters-applied" */'./AdvertsListAdvancedFiltersApplied'));

interface AdvertsListFiltersProps {
  urlGenerator: IHomePageUrlGenerator;
}

// let renderCounter = 0;

const AdvertsListFilters: React.FC<AdvertsListFiltersProps> = ({ urlGenerator }: AdvertsListFiltersProps) => {

  // console.debug(`Render AdvertsListFiltersAnimals ${++renderCounter}`);

  const isAdvancedFiltersApplied = urlGenerator.query.filters?.gender !== undefined ||
    urlGenerator.query.filters?.createdFrom !== undefined ||
    urlGenerator.query.filters?.createdTo !== undefined;

  const currentQueryUi: DeepReadonly<HomePageQuery> = useMemo(() => {
    const result: DeepReadonly<HomePageQuery> = {
      ...urlGenerator.queryForBuildingUrl,
      pagination: undefined,
      active: undefined,
      activePin: undefined,
    };

    return result;
  }, [urlGenerator.queryForBuildingUrl]);

  const currentQuerySeo: DeepReadonly<HomePageQuery> = useMemo(() => {
    const result: DeepReadonly<HomePageQuery> = {
      ...currentQueryUi,
      map: undefined,
    };

    return result;
  }, [currentQueryUi]);

  return (
    <>
      {isAdvancedFiltersApplied && (
        <AdvertsListAdvancedFiltersApplied urlGenerator={urlGenerator} />
      )}
      <aside id={styles['app-aside']}>
        <div className={styles['app-div']}>
          <span className={styles['app-label']}>Zwierzę:{' '}</span>
          <div>
            <AdvertsListFiltersAnimals
              urlGenerator={urlGenerator}
              currentQueryUi={currentQueryUi}
              currentQuerySeo={currentQuerySeo}
            />
          </div>
        </div>
        <div className={styles['app-div']}>
          <span className={styles['app-label']}>Rodzaj ogłoszenia:{' '}</span>
          <div>
            <AdvertsListFiltersTypes
              urlGenerator={urlGenerator}
              currentQueryUi={currentQueryUi}
              currentQuerySeo={currentQuerySeo}
            />
          </div>
        </div>
        <div>
          <span className={styles['app-label']}>Opublikowane:{' '}</span>
          <div>
            <AdvertPublicCreatedAtPeriodDropdownNative urlGenerator={urlGenerator} />
          </div>
        </div>
      </aside>
      {urlGenerator.query.expand && (
        <AdvertsListAdvancedFiltersExpanded urlGenerator={urlGenerator} />
      )}
    </>
  );
};

export default AdvertsListFilters;
